<template>
  <div style="padding: 0px">
    <a-row
      style="height: 48px; background: black; padding: 0px 16px"
      align="middle"
      type="flex"
    >
      <a-col
        ><div style="color: white; font-size: 24px">Parlano di noi</div></a-col
      >
    </a-row>
    <a-row :gutter="12" style="padding: 0px 12px">
      <a-col :span="inEdit ? 16 : 24" style="padding: 12px">
        <a-row>
          <a-col style="padding: 12px 0">
            <a-button @click="newItem" type="primary">NUOVO</a-button>
          </a-col>
        </a-row>
        <div
          id="table"
          style="
            height: calc(100vh - 140px);
            background: white;
            border: 1px solid #aaaaaa;
          "
        ></div
      ></a-col>
      <a-col :span="inEdit ? 8 : 0">
        <div
          style="
            background: #fafafa;
            padding: 12px;
            box-sizing: border-box;
            overflow-y: scroll;
            height: calc(100vh - 48px);
          "
        >
          <a-row type="flex" align="middle" justify="space-between">
            <a-col><h4>Edita</h4></a-col>
            <a-col><a @click="chiudi">chiudi</a></a-col></a-row
          >

          <a-form
            id="components-form-demo-validate-other"
            :form="form"
            @submit="handleSubmit"
          >
            <a-form-item label="Titolo">
              <a-input v-decorator="['titolo', { initialValue: 'Titolo' }]">
              </a-input>
            </a-form-item>
            <a-form-item label="Testata">
              <a-input v-decorator="['testata', { initialValue: 'Testata' }]">
              </a-input>
            </a-form-item>


            <a-form-item label="Data">
              <a-date-picker format="YYYY-MM-DD" v-decorator="['date', {initialValue: null}]" />
            </a-form-item>

            <a-form-item label="Link">
              <a-input v-decorator="['link', { initialValue: 'Link' }]">
              </a-input>
            </a-form-item>

            <a-form-item
              style="width: 100%"
              :wrapper-col="{ span: 24, offset: 0 }"
            >
              <a-button style="width: 100%" type="primary" html-type="submit">
                Salva
              </a-button>
            </a-form-item>
          </a-form>
        </div></a-col
      ></a-row
    >
  </div>
</template>

<script>
const axios = require("axios");
import { format } from 'date-fns'
import Tabulator from "tabulator-tables";
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "validate_other" });
  },
  computed: {},
  watch: {
    parlanodinoi() {
      this.table.setData(this.parlanodinoi);
    },
  },
  async mounted() {
    this.getParlanodinoi();
    this.table = new Tabulator(this.$el.querySelector("#table"), {
      // set height of table (in CSS or here), this enables the Virtual DOM and improves render speed dramatically (can be any valid css height value)
      data: this.places, //assign data to table

      layout: "fitColumns", //fit columns to width of table (optional)
      columns: [
        {
          width: 192,
          field: "titolo",
          title: "Titolo",
          formatter(e) {
            return `<span style="font-weight:400">${e.getValue()}</span>`;
          },
        },
        {
          width: 192,
          field: "testata",
          title: "Testata",
          formatter(e) {
            return `<span style="font-weight:400">${e.getValue()}</span>`;
          },
        },
        {
          width: 192,
          field: "date",
          title: "Data",
          formatter(e) {
            return `<span style="font-weight:400">${e.getValue()}</span>`;
          },
        },
        {
          width: 192,
          field: "link",
          title: "Link",
          // formatter(e) {
          //   return `<span style="font-weight:400">${e.getValue()}</span>`;
          // },
        },
      ],
      rowClick: async function (e, row) {
        this.inEdit = true;
        this.currentItem = {
          ...row.getData(),
        };

        this.form.setFieldsValue({
          ...this.currentItem,
        });
        //trigger an alert message when the row is clicked
      }.bind(this),
    });
  },
  methods: {
    format,
    async getParlanodinoi() {
      this.parlanodinoi = (await axios.get("/vmuts/api/parlanodinoi")).data;
    },
    chiudi() {
      this.inEdit = false;
      this.form.resetFields();
    },
    handleSubmit(e) {
      this.inEdit = false;
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          console.log(values.date.toString())
          let itemToSave = { ...this.currentItem, ...values,date:values.date.toString() };

          await axios.post("/vmuts/api/parlanodinoi", itemToSave);
          this.getParlanodinoi();

          console.log("Received values of form: ", values);
        }
      });
    },

    newItem() {
      this.form.resetFields();
      this.currentItem = {};
      this.currentItem.key = null;
      this.internalId = new Date().getTime();
      this.form.setFieldsValue({});
      this.inEdit = true;
    },
    add() {},
  },
  data() {
    return {
      categories: [],
      macro_categories: [],
      places: [],
      defaultFileList: [],
      fileList: [],
      internalId: null,
      inEdit: false,
      parlanodinoi: [],
      currentItem: {
        date:null
      },
      labelPosition: "on-border",
    };
  },
};
</script>
<style>
button {
  font-size: 13px;
  padding: 0px 4px;
}
.tabulator-row {
  font-family: "Poppins";
}
.tabulator-row .tabulator-cell {
  padding: 5px !important;
  font-size: 13px;

  border-left: 1px solid #e3e3e3;
}
#table {
  width: 100%;
}
.tabulator-cell[tabulator-field="name"] {
  background: #f2f2f2;
}
.tabulator-col-title {
  font-size: 12px;
  font-weight: 300;
  padding-left: 0;
}
.tabulator-col .tabulator-sortable {
  height: 24px !important;
}
.tabulator-col-content {
  background: #fafafa;
}
.tabulator-header {
  border: 1px solid #e3e3e3 !important;
}
.mini_badge {
  height: 22px;
  font-size: 9px;
  text-transform: uppercase;
  border: 1px solid #999999;
  background: #fafafa;
  color: #474747;
  margin-right: 4px;
  border-radius: 4px;
  padding: 1px 3px;
}
.tabulator {
  border: 0 !important;
  border-bottom: 1px solid #eaeaea !important;
}
</style>
